/* 
MAIN COLOR: #f08c00
SECOND COLOR: #fab005
GRAY COLOR: #343a40
*/

/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
*/


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  color: #343a40;
  line-height: 1;
}

html,
body,
#root,
.App {
  height: 100%;
}

.hidden {
  display: none;
}

.eventTimerPage {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.eventTimerPageSection {
  background-color: #fab005;
  width: 95%;
  padding: 15px 30px;
  margin: 0px auto;
  border-radius: 8px;
}


/* Tools header */
.eventTimerPageToolsHeader {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
  height: 20px;
}

.toolButton {
  border: none;
  background-color: #fff;
  border-radius: 50%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolButtonIcon {
  stroke: #f08c00;
  height: 20px;
  width: 20px;
}

/* Introduction card */
.eventTimerHeader {
  margin-top: 8px;
}

.introductionTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 12px;
  letter-spacing: -1px;
}

.introductionDescription {
  font-size: 14px;
  line-height: 1.5;
}

/* event table */
.eventTable {
  flex: 1;
  padding: 15px 20px;
  overflow: auto;
}

.eventItem {
  background-color: #f08c00;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
  height: 70px;
}

.highlight {
  color: #212529;
}

.eventItemImage {
  width: 50px;
  border-radius: 8px;
}

.eventTableEnd {
  text-align: center;
}


/* control panel */

.controlPanel {
  margin-bottom: 8px;
}

.clockPanel {
  margin-bottom: 12px;
}

.playButtonHint {
  text-align: center;
  color: #495057;
  font-size: 12px;
}

.clock {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
}

.controlButtons {
  display: flex;
  justify-content: center;
  /* gap: 16px; */
  height: 60px;
  gap: 8px;
}

.controlButton {
  border: none;
  background-color: #fff;
  color: #f08c00;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;

  font-size: 16px;

  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
}

.controlButton:active {
  /* background-color: #f1f3f5; */
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.2);
}

.buttonIcon {
  height: 25px;
}

/* setting */
.settingPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.settingHeader {
  background-color: #fab005;
  width: 95%;
  padding: 15px 30px;
  margin: 8px auto;
  border-radius: 8px;
}

.settingSection {
  background-color: #fab005;
  width: 95%;
  padding: 15px 30px;
  margin: 0 auto;
  margin-bottom: 8px;
  border-radius: 8px;

  flex: 1;
  overflow: scroll;
}

.settingSection h2 {
  margin-bottom: 8px;
}

.settingButtons {
  background-color: #fab005;
  width: 95%;
  padding: 10px 15px;
  margin: 0 auto;
  margin-bottom: 8px;
  border-radius: 8px;
}

.eventSettingItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.settingItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  gap: 12px;

  background-color: #f08c00;
  padding: 15px 30px;
  border-radius: 8px;
}

.settingItemImage {
  width: 50px;
  border-radius: 8px;
}

.settingItemDesc {
  width: 200px;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.settingItemName {
  width: 68px;
  text-align: center;
}

.settingItemInput {
  height: 100%;
  width: 300px;
  display: flex;
  gap: 12px;
}

.settingCheckItem {
  display: flex;
  align-items: center;
  gap: 2px;
}

.settingButtons {
  display: flex;
  justify-content: space-around;
}